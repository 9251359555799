body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 12px;
  margin: 0;
  padding: 0;
}

/* Links */
a {
  color: #337abd;
}

a:hover {
  color: #ed1c24;
}
/* /links */

/* Headings */
h1,
h2,
h3 {
  font-weight: lighter;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 18px;
}
/* /Headings */

/* Forms */
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
/* /Forms */

/* Alignment classes */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
/* /Alignment classes */

/* Buttons */
.btn {
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  outline: none;
}

.btn:focus {
  border-color: #66afe9;
  box-shadow: 0 0 8px rgba(102,175,233,0.9);
}

.btn-default {
  background-color: #eee;
  background-image: linear-gradient(#fcfcfc, #eee);
  border: 1px solid #d5d5d5;
  color: #000;
}

.btn-default:hover {
  background-color: #ddd;
  background-image: linear-gradient(#eee, #ddd);
  border-color: #ccc;
  color: #000;
}

.btn-default:active {
  background-color: #dcdcdc;
  background-image: none;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.15);
}

.btn-primary {
  background-color: #60b044;
  background-image: linear-gradient(#8add6d, #60b044);
  border: 1px solid #5ca941;
  color: #fff;
}

.btn-primary:hover {
  background-color: #569e3d;
  background-image: linear-gradient(#79d858, #569e3d);
  border-color: #4a993e;
  color: #fff;
}

.btn-primary:active {
  background-color: #569e3d;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.15);
}

.btn-big {
  font-size: 16px;
}

.btn:disabled {
  background: #eee;
  border-color: #ccc;
  color: #ccc;
  cursor: default;
}
/* /Buttons */

/* Form controls */
label {
  color: #666;
}

input {
  transition: all 0.1s linear;
}

input:focus {
  transition: all 0.1s linear;
}

input[type=date],
input[type=email],
input[type=password],
input[type=search],
input[type=text],
input[type=time],
select {
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
  box-sizing: border-box;
  outline: none;
  padding: 8px;
}

input[type=date]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=text]:focus,
input[type=time]:focus,
select:focus {
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.3), 0 0 4px rgba(102,175,233,0.9);
}

input[type=radio].invalid + label:before,
input.invalid { /* input:invalid */
  border-color: #ff9595;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.3), 0 0 4px rgba(255,149,149,0.2);
}

input[type=radio].invalid:focus + label:before,
input.invalid:focus { /* input:invalid:focus */
  border-color: #ff9595;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.3), 0 0 8px rgba(255,149,149,0.9);
}

input[type=radio] {
  opacity: 0;
  position: absolute;
}

input[type=radio] + label {
	/*cursor: pointer;*/
	display: inline-block;
	font-size: 12px;
	line-height: 18px;
	margin-right: 16px;
	padding-left: 26px;
	position: relative;
}

input[type=radio] + label:before {
  background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 18px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.3);
	box-sizing: border-box;
	content: "";
	display: inline-block;
	height: 18px;
	left: 0;
	position: absolute;
	top: 0;
	width: 18px;
}

input[type=radio]:checked + label:after {
	background-color: #66afe9;
	border-radius: 10px;
	content: "";
	display: inline-block;
	height: 10px;
	left: 4px;
	position: absolute;
	top: 4px;
	width: 10px;
}

input[type=radio]:focus + label:before {
	border-color: #66afe9;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.3), 0 0 4px rgba(102,175,233,0.9);
}

input[type=radio]:disabled + label {
  color: #ccc;
}

input[type=radio]:disabled + label:before {
	background: #eee;
  border-color: #eee;
  box-shadow: none;
}
/* /Form controls */
